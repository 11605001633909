/* You can add global styles to this file, and also import other style files */
/* .modal-dialog{
    width: 1200px;;
    height: 100%  ;
   margin: 0 10px 0 0;
  }
  .modal-content{
    width:1200px;
    height: 100%  ;
  } */
  @import "@angular/material/prebuilt-themes/indigo-pink.css";
  .demo-chart {
    height: 400px;
  }
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #e6732e;
  border-color: #e6732e;
}
.page-link {
  color: #e6732e;
}
.page-link:hover {
  color: #fcc623;
}
.btn {
  /*background-color: #009cb3;*/
  border:2px solid #009cb3;
}
.btn:hover {
  background-color: #e6732e;
  border:2px solid #009cb3;
}
.nav-tabs li a{
color:  #009cb3;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  background-color: #e6732e; 
  color:#fff;
}
.Search {
  color:  #009cb3;
}
.heading {
  background: #009cb3 !important;
  color: #fff;
  padding:5px 0 0 5px;
  border-radius: 5px;
}
label {
  font-size: 14px !important;
}
.pagination-page {
  display: none !important;
}